:root {
  --maincolor: #e96036;
}
@media (max-width: 639px) {
  #user-menu ~ #user-menu-dropdown {
    transform: scaleX(0) scaleY(0);
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 75ms;
    opacity: 0;
    top: 3.25rem;
  }

  #user-menu ~ #user-menu-dropdown:focus-within,
  #user-menu:focus ~ #user-menu-dropdown {
    transform: scaleX(1) scaleY(1);
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 100ms;
    opacity: 1;
  }
}

.nav-links:hover,
.sub-link-menu:hover,
.signup-btn-mobile,
.active {
  background-color: var(--maincolor) !important;
  color: white !important;
}

.dropdown-nav {
  padding: 10px;
  width: fit-content;
  position: absolute;
  top: 80px;
  list-style: none;
}

.nav-links > ul {
  transform: translatex(100%) scale(0);
}
.nav-links:hover > ul {
  transform: translatex(101%) scale(1);
}
.nav-links > svg {
  margin-left: 5px;
  width: 16px;
}

.nav-links .group:hover .nav-links .group-hover\:scale-100 {
  transform: scale(1);
}
.nav-links .scale-0 {
  transform: scale(0);
}
.nav-links .min-w-32 {
  min-width: 8rem;
}

.mobile-menu {
  @apply fixed z-10 top-[96px] right-0 w-full grid grid-flow-row auto-rows-max gap-6 pb-8 text-center place-content-center bg-white shadow-xl transition duration-300 ease-in-out text-white;
  transform-origin: top center;
  animation: rotateX 300ms ease-in-out forwards;
}
.mobile-menu a {
  color: var(--maincolor);
}
.mobile-menu-hide {
  transform-origin: top center;
  /* animation: rotateX 300ms ease-in-out forwards; */
}
.ant-select {
  display: inline-flex !important;
  vertical-align: middle !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.ant-select-item-option {
  display: block !important;
}
.ant-select-item-option-content {
  flex: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
