@media (max-width: 639px) {
  .google-login-btn,
  .facebook-login-btn,
  .github-login-btn {
    width: 100% !important;
  }
  .instructor--header {
    margin-top: 0px !important;
    height: 133px !important;
  }
}
.about svg,
.instructor_social svg {
  transition: 0.2s all;
}
.about svg {
  fill: #636363 !important;
}
.about svg:hover {
  fill: #c97475 !important;
  padding: 0 !important;
}
.instructor_social svg:hover {
  fill: #e96036 !important;
  padding: 0 !important;
}
.instructor--header {
  background: url("./../../../../assets/images/bg-instructor.png");
  margin-top: 90px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 400px;
}
.user-page {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin: 180px !important;
  font-family: Arial, Helvetica, sans-serif;
  max-height: fit-content !important;
}
.user-page svg.logo-image {
  width: 400px !important;
}
.ant-modal-content {
  border-radius: 10px !important;
}
.ant-modal-body {
  padding: 24px 24px 0 24px !important;
}
.passwordSignIn input[type="password"] {
  border-color: none !important;
  border-top-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 1px;
  border-left-width: 0 !important;
}
.line-modal {
  width: 1px;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(10, 3, 60, 0) 0%,
    #0a033c 51.56%,
    rgba(10, 3, 60, 0) 100%
  );
}

.google-login-btn:hover .google-login {
  border: 1px solid #fff !important;
}
.google-login-btn:hover,
.facebook-login-btn:hover,
.github-login-btn:hover {
  background-color: #e96036;
  color: #fff;
  border-color: #e96036 !important;
}

.google-login-btn,
.facebook-login-btn,
.github-login-btn {
  width: fit-content;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #ddd;
}

.google-login {
  background-color: #e96036 !important;
  padding: 5px;
}

.floating-two {
  margin-top: -44px;
  margin-right: -2px;
  animation-name: floating-two;
  -webkit-animation-name: floating-two;
  animation-duration: 3s;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes floating-two {
  0% {
    -webkit-transform: rotate(0deg) translateX(4px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn) translateX(4px) rotate(-1turn);
  }
}
@keyframes floating-two {
  0% {
    -webkit-transform: rotate(0deg) translateX(4px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn) translateX(4px) rotate(-1turn);
  }
}
