@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Helv-neue";
  src: url("./assets/fonts/helveticaneuelight.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Helv-neue";
  src: url("./assets/fonts/helveticaneue.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Helv-neue";
  src: url("./assets/fonts/helveticaneuemedium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Helv-neue";
  src: url("./assets/fonts/helveticaneuebold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Helv-neue";
  src: url("./assets/fonts/helveticaneuecondensedblack.ttf");
  font-weight: 700;
}
body {
  font-family: "Helv-neue" !important;
  font-weight: normal;
}
h1 {
  font-family: "Helv-neue" !important;
  font-weight: 600 !important;
}
